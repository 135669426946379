import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import moment from 'moment';
import LayoutLogged from '../LayoutLogged';
import api from '../../services/api';
import modalManager from '../../services/modalManager';
import InputTypeFloat from '../../components/Html/InputTypeFloat';
import { LoggedContext } from '../../contexts/LoggedContext';
import {
  OrdemProducao as OrdemProducaoModel,
  Pasta as PastaModel,
  PecasSegundaQualidade as PecasSegundaQualidadeModel,
  Disponibilidade as DisponibilidadeModel,
  Evento as EventoModel
} from '../../models/OPEstamparia';
import Pasta from '../../components/OPEstamparia/PastaForm';
import Apontamentos from '../../components/OPEstamparia/Apontamentos';
import ModalCliente from '../../components/Cliente/ModalCliente';
import RemoveEventoDialog from '../../components/OPEstamparia/RemoveEventoDialog';
import RemoveDisponibilidadeDialog from '../../components/OPEstamparia/RemoveDisponibilidadeDialog';
import RemoveMaquinaDialog from '../../components/OPEstamparia/RemoveMaquinaDialog';
import ModalDisponibilidadeForm from '../../components/OPEstamparia/ModalDisponibilidadeForm';
import ModalEventoForm from '../../components/OPEstamparia/ModalEventoForm';
import ModalMaquinaForm from '../../components/OPEstamparia/ModalMaquinaForm';
import './StatusOp.css';
import './FormPage.css';

export default function FormPage() {

  const ANO_MINIMO_PERMITIDO = 2018;
  let history = useHistory();
  const context = useContext(LoggedContext);
  const { ordemId } = useParams();
  const [ordemOriginal, setOrdemOriginal] = useState({ ...OrdemProducaoModel });
  const [ordem, _setOrdem] = useState({ ...OrdemProducaoModel });
  const setOrdem = function (props) {
    _setOrdem(props);
    setOrdemFoiModificada(true);
  }
  const [ordemFoiModificada, setOrdemFoiModificada] = useState(false);
  const [savingFlag, setSavingFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [idOpCopiada, setIdOpCopiada] = useState();

  useEffect(() => {
    carregaOrdem();
  }, [ordemId]);

  function carregaOrdem() {
    setIdOpCopiada();
    if (ordemId) {
      carregaOrdemDoServidor();
    } else if (context.areaDeTransferencia) {
      carregaOrdemDaAreaDeTransferencia();
    } else {
      carregaOrdemEmBranco();
    }
  }

  async function carregaOrdemDoServidor() {
    let resp;
    try {
      setIsLoading(true);
      resp = await api.get(`/op-estamparia/${ordemId}`);
    } catch (error) {
      setLoadError(true);
      if (error.response.status < 500) {
        alert(error.response.data.description);
      }
      return;
    } finally {
      setIsLoading(false);
    }
    setOrdemOriginal(JSON.parse(JSON.stringify(resp.data)));
    _setOrdem(JSON.parse(JSON.stringify(resp.data)));
  }

  function carregaOrdemDaAreaDeTransferencia() {
    let ordem = JSON.parse(JSON.stringify(OrdemProducaoModel));
    ordem.cliente = context.areaDeTransferencia.cliente;
    ordem.lacrePiloto = context.areaDeTransferencia.lacrePiloto;
    ordem.notaFiscal = context.areaDeTransferencia.notaFiscal;
    ordem.referencia = context.areaDeTransferencia.referencia;
    ordem.entrada = context.areaDeTransferencia.entrada;
    ordem.previsaoEntrega = context.areaDeTransferencia.previsaoEntrega;
    ordem.projecaoProdutividadePorHora = context.areaDeTransferencia.projecaoProdutividadePorHora;
    ordem.quantidadeQuadros = context.areaDeTransferencia.quantidadeQuadros;
    ordem.ofCliente = context.areaDeTransferencia.ofCliente;
    ordem.marca = context.areaDeTransferencia.marca;
    ordem.quantidade = context.areaDeTransferencia.quantidade;
    ordem.precoEstampa = context.areaDeTransferencia.precoEstampa;
    ordem.informacoesProducao = context.areaDeTransferencia.informacoesProducao;
    setOrdemOriginal(ordem);
    _setOrdem(JSON.parse(JSON.stringify(ordem)));
    setIdOpCopiada(context.areaDeTransferencia.id);
    context.setAreaDeTransferencia(null);
  }

  function carregaOrdemEmBranco() {
    setOrdemOriginal({ ...OrdemProducaoModel });
    _setOrdem({ ...OrdemProducaoModel });
  }

  function showModalSelecionarCliente() {
    modalManager.mount(
      'modalCliente',
      <ModalCliente
        onSelect={(e, cliente) => {
          e.preventDefault();
          ordem.cliente = { ...cliente };
          setOrdem({ ...ordem });
          modalManager.hide();
        }}
      />
    );
    modalManager.show('modalCliente');
  }

  function adicionaPasta(e) {
    e.preventDefault();
    if (!ordem.pastas) {
      ordem.pastas = [];
    }
    ordem.pastas = [...ordem.pastas, { ...PastaModel }];
    setOrdem({ ...ordem });
  }

  function adicionaPecasSegundaQualidade(e) {
    e.preventDefault();
    if (!ordem.pecasSegundaQualidade) {
      ordem.pecasSegundaQualidade = [];
    }
    ordem.pecasSegundaQualidade = [
      ...ordem.pecasSegundaQualidade,
      { ...PecasSegundaQualidadeModel }
    ];
    setOrdem({ ...ordem });
  }

  function cancelaAlteracoesNoForm(e) {
    e.preventDefault();
    setOrdem({ ...ordemOriginal });
    setOrdemFoiModificada(false);
  }

  function copiar(e) {
    e.preventDefault();
    context.setAreaDeTransferencia(JSON.parse(JSON.stringify(ordemOriginal)));
    history.push("/op-estamparia/form");
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (!validaForm()) {
      return false;
    }
    setSavingFlag(true);
    const toastId = toast('Salvando...', {
      autoClose: false
    });
    let resp;
    try {
      resp = await api.post('/op-estamparia', ordem);
    } catch (error) {
      let msg = (error.response.status < 500)
        ? error.response.data.description
        : "Ocorreu um erro no servidor. Tente novamente ou entre em contato com o suporte.";
      toast.update(toastId, {
        render: msg,
        type: toast.TYPE.ERROR,
        autoClose: 10000
      });
      setSavingFlag(false);
      return;
    }

    setOrdemOriginal(JSON.parse(JSON.stringify(resp.data)));
    _setOrdem(JSON.parse(JSON.stringify(resp.data)));
    setIdOpCopiada();
    toast.update(toastId, {
      render: "Salvo com sucesso.",
      type: toast.TYPE.SUCCESS,
      autoClose: 4000,
    });
    setSavingFlag(false);
    setOrdemFoiModificada(false);
  }

  function validaForm() {
    if (ordem.entrada === "") {
      alert('É necessário escolher a Data de Entrada.');
      return false;
    }
    if (ordem.cliente.id < 1) {
      alert('É necessário escolher um Cliente.');
      return false;
    }
    return true;
  }

  function permiteEditar() {
    return ['cancelada', 'finalizada'].indexOf(ordemOriginal.status) > -1 ? true : true;
  }

  return (
    <LayoutLogged>
      <h2 className="page-title">Ordem de Produção de Estampas {ordem?.id ? ` (ID ${ordem?.id})` : ''}</h2>
      {isLoading &&
        <p>Carregando Ordem de Produção...</p>
      }
      {!isLoading && loadError &&
        <p>Erro ao carregar a Ordem de Produção. Tente novamente ou entre em contato com o suporte.</p>
      }
      {!isLoading && !loadError &&
        <>
          <ul className="nav nav-pills" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" data-toggle="tab" href="#tab-form" role="tab">
                Formulário
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#tab-apontamentos" role="tab">
                Apontamentos
              </a>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${!ordem.id ? 'disabled' : ''}`}
                to={`/op-estamparia/${ordem.id}/print`}>
                Imprimir
              </Link>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="tab-form" role="tabpanel">
              <div className={`alert alert-info mb-4 fade ${idOpCopiada > 0 ? 'show' : 'd-none'}`} role="alert">
                Você agora está cadastrando uma nova Ordem de Produção com os mesmos dados da OP ID {idOpCopiada}
              </div>
              <form
                className={`ordemproducao-form ${(!permiteEditar() ? 'ordemproducao-form--naopermiteeditar' : '')}`}
                onSubmit={handleSubmit}
              >
                <fieldset>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label>Status da OP</label>
                      <div>
                        <select
                          className={`custom-select statusop-select status-${ordem.status}`}
                          value={ordem.status}
                          onChange={e => {
                            e.target.classList.remove('status-aberta');
                            e.target.classList.remove('status-producao');
                            e.target.classList.remove('status-fechada');
                            e.target.classList.remove('status-finalizada');
                            e.target.classList.remove('status-cancelada');
                            e.target.classList.add('status-' + e.target.value);
                            ordem.status = e.target.value;
                            setOrdem({ ...ordem });
                          }}
                          disabled={!permiteEditar()}>
                          <option value="aberta" className="statusop-aberta-color">Aberta</option>
                          <option value="producao" className="statusop-producao-color">Produção</option>
                          <option value="fechada" className="statusop-fechada-color">Fechada</option>
                          <option value="finalizada" className="statusop-finalizada-color">Finalizada</option>
                          <option value="cancelada" className="statusop-cancelada-color">Cancelada</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group col-md-3">
                      <label>Data Entrada</label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        value={(ordem.entrada ? ordem.entrada.substr(0, 16) : '') || ""}
                        onChange={e => {
                          ordem.entrada = e.target.value;
                          setOrdem({ ...ordem });
                        }}
                        onBlur={e => {
                          let ano = e.target.value.substr(0, 4);
                          if (ano && ano < ANO_MINIMO_PERMITIDO) {
                            alert('O ano da data de entrada não pode ser inferior a ' + ANO_MINIMO_PERMITIDO + '. Você digitou: ' + ano);
                          }
                        }}
                        disabled={!permiteEditar()}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label>Cliente</label>
                      <div className={`${ordem.cliente.id > 0 ? 'form-control' : ''}`}>
                        {ordem.cliente.id == 0 &&
                          <button
                            type="button"
                            className="btn btn-secondary"
                            style={{width: '100%'}}
                            onClick={e => {
                              e.preventDefault();
                              if (!permiteEditar()) {
                                return;
                              }
                              showModalSelecionarCliente();
                            }}>
                            Selecionar Cliente
                          </button>
                        }
                        {ordem.cliente.id > 0 &&
                          <a href="#"
                            onClick={e => {
                              e.preventDefault();
                              if (!permiteEditar()) {
                                return;
                              }
                              showModalSelecionarCliente();
                            }}>{ordem.cliente.nome}</a>
                        }
                      </div>
                    </div>
                    <div className="form-group col-md-2">
                      <label>Nota Fiscal</label>
                      <input
                        className="form-control"
                        value={ordem.notaFiscal || ""}
                        onChange={e => {
                          ordem.notaFiscal = e.target.value;
                          setOrdem({ ...ordem });
                        }}
                        disabled={!permiteEditar()}
                      />
                    </div>
                    <div className="form-group col-md-2">
                      <label>Lacre Piloto</label>
                      <input
                        className="form-control"
                        value={ordem.lacrePiloto || ""}
                        onChange={e => {
                          ordem.lacrePiloto = e.target.value;
                          setOrdem({ ...ordem });
                        }}
                        disabled={!permiteEditar()}
                      />
                    </div>
                    <div className="form-group col-md-2">
                      <label>Referência</label>
                      <input
                        className="form-control"
                        value={ordem.referencia || ""}
                        onChange={e => {
                          ordem.referencia = e.target.value;
                          setOrdem({ ...ordem });
                        }}
                        disabled={!permiteEditar()}
                      />
                    </div>
                    <div className="form-group col-md-2">
                      <label>O.F. do Cliente</label>
                      <input
                        className="form-control"
                        value={ordem.ofCliente || ""}
                        onChange={e => {
                          ordem.ofCliente = e.target.value;
                          setOrdem({ ...ordem });
                        }}
                        disabled={!permiteEditar()}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label>Marca</label>
                      <input
                        className="form-control"
                        value={ordem.marca || ""}
                        onChange={e => {
                          ordem.marca = e.target.value;
                          setOrdem({ ...ordem });
                        }}
                        disabled={!permiteEditar()}
                      />
                    </div>
                    <div className="form-group col-md-2">
                      <label>Quantidade de Peças</label>
                      <input
                        type="number"
                        className="form-control"
                        value={ordem.quantidade === null ? "" : ordem.quantidade}
                        onChange={e => {
                          ordem.quantidade = e.target.value;
                          setOrdem({ ...ordem });
                        }}
                        disabled={!permiteEditar()}
                      />
                    </div>
                    <div className="form-group col-md-2">
                      <label>Preço da Estampa</label>
                      <InputTypeFloat
                        className="form-control"
                        value={ordem.precoEstampa}
                        onChange={value => {
                          ordem.precoEstampa = value;
                          setOrdem({ ...ordem });
                        }}
                        disabled={!permiteEditar()}
                      />
                      <small className="form-text text-muted text-right">
                        Total: R$ {Intl.NumberFormat(['pt-BR'], { minimumFractionDigits: 2}).format((ordem.quantidade || 0) * (ordem.precoEstampa || 0))}
                      </small>
                    </div>
                    <div className="form-group col-md-2">
                      <label
                        title="Quantidade de peças que você estima produzir por hora.">
                        Produtividade p/ Hora
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        value={ordem.projecaoProdutividadePorHora}
                        onChange={e => {
                          ordem.projecaoProdutividadePorHora = e.target.value;
                          setOrdem({ ...ordem });
                        }}
                        disabled={!permiteEditar()}
                        title="Quantidade de peças que você estima produzir por hora."
                      />
                    </div>
                    <div className="form-group col-md-2">
                      <label>Quantidade de Quadros</label>
                      <select className="custom-select"
                        value={ordem.quantidadeQuadros || ""}
                        onChange={e => {
                          ordem.quantidadeQuadros = e.target.value;
                          setOrdem({ ...ordem });
                        }}>
                          <option value=""></option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label>Data Previsão Entrega</label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        value={(ordem.previsaoEntrega ? ordem.previsaoEntrega.substr(0, 16) : '') || ""}
                        onChange={e => {
                          ordem.previsaoEntrega = e.target.value;
                          setOrdem({ ...ordem });
                        }}
                        disabled={!permiteEditar()}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label>Data Entrega</label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        value={(ordem.entrega ? ordem.entrega.substr(0, 16) : '') || ""}
                        onChange={e => {
                          ordem.entrega = e.target.value;
                          setOrdem({ ...ordem });
                        }}
                        disabled={!permiteEditar()}
                      />
                    </div>
                  </div>
                </fieldset>

                <fieldset>
                  <h3>Preparação das Pastas</h3>
                  <div className="form-group-fluid">
                    <Pasta ordem={ordem} setOrdem={setOrdem} permiteEditar={permiteEditar} />
                  </div>
                  {
                    permiteEditar() ?
                      <button type="button" onClick={adicionaPasta}>Adicionar Pasta</button>
                      : ''
                  }
                </fieldset>

                <fieldset>
                  <h2>Quadro</h2>
                  <fieldset>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label>Código</label>
                        <input
                          className="form-control"
                          value={ordem.quadro || ""}
                          onChange={e => {
                            ordem.quadro = e.target.value;
                            setOrdem({ ...ordem });
                          }}
                          disabled={!permiteEditar()}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-12">
                        <label>Informações p/ produção</label>
                        <textarea
                          className="form-control"
                          value={ordem.informacoesProducao || ""}
                          onChange={e => {
                            ordem.informacoesProducao = e.target.value;
                            setOrdem({ ...ordem });
                          }}
                          disabled={!permiteEditar()}
                        ></textarea>
                      </div>
                    </div>
                  </fieldset>
                </fieldset>

                <fieldset>
                  <h2>Eventos de Produção</h2>
                  <EventosDeProducao ordem={ordem} setOrdem={setOrdem} permiteEditar={permiteEditar} />
                </fieldset>

                <fieldset>
                  <h3>Peças de Segunda Qualidade</h3>
                  <div className="form-group-fluid">
                    {(ordem.pecasSegundaQualidade || []).map((peca, index) => (
                      <div key={index} className="form-group -box">
                        <div className="form-row">
                          <div className="form-group col-md-12">
                            <label>Tamanho</label>
                            <input type="text" className="form-control"
                              value={peca.tamanho || ""}
                              onChange={e => {
                                peca.tamanho = e.target.value;
                                setOrdem({ ...ordem });
                              }}
                            />
                          </div>
                          <div className="form-group col-md-12">
                            <label>Quantidade</label>
                            <input type="number" className="form-control"
                              value={peca.quantidade || ""}
                              onChange={e => {
                                peca.quantidade = e.target.value;
                                setOrdem({ ...ordem });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {
                    permiteEditar() ?
                      <button type="button" onClick={adicionaPecasSegundaQualidade}>Adicionar Peças de Segunda Qualidade</button>
                      : ''
                  }
                </fieldset>

                <div className="form-footeractions">
                  <div className="container">
                    <div className="btn-group mr-2">
                      <button type="button" className="btn btn-primary"
                        disabled={savingFlag || (ordem?.id > 0 && !ordemFoiModificada)}
                        onClick={handleSubmit}>
                        {savingFlag ? "Salvando..." : "Salvar"}
                      </button>
                    </div>
                    <div className="btn-group mr-4">
                      <button 
                        type="button" 
                        disabled={!ordemFoiModificada}
                        className="btn btn-secondary" 
                        onClick={cancelaAlteracoesNoForm}>
                          Não Salvar
                      </button>
                    </div>
                    <div className="btn-group mr-2">
                      <button 
                        disabled={ordem?.id < 1}
                        type="button" 
                        className="btn btn-secondary" 
                        onClick={copiar}>
                          Copiar para nova OP
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="tab-pane fade" id="tab-apontamentos" role="tabpanel">
              <Apontamentos ordem={ordem} />
            </div>

          </div>
        </>
      }
    </LayoutLogged>
  );
}

function EventosDeProducao(props) {

  const context = useContext(LoggedContext);
  const ordem = props.ordem;
  const setOrdem = props.setOrdem;
  const permiteEditar = props.permiteEditar;

  function onClickAdicionarMaquina(e) {
    e.preventDefault();
    showModalMaquinaForm();
  }

  function filtraMaquinasQueNaoEstaoEmUso() {
    const maquinasEmUso = [];
    (ordem.maquinas || []).map(maq =>
      maquinasEmUso.push(maq.id)
    );
    return (context.maquinasCache || []).filter(maq =>
      maquinasEmUso.indexOf(maq.id) > -1 ? false : true
    );
  }

  function showModalMaquinaForm() {
    modalManager.mount(
      'modalMaquinaForm',
      <ModalMaquinaForm
        maquinas={filtraMaquinasQueNaoEstaoEmUso()}
        onConfirm={(e, maquinaSelecionada) => {
          e.preventDefault();
          ordem.maquinas = [...ordem.maquinas, { ...maquinaSelecionada }];
          setOrdem({ ...ordem });
          modalManager.hide();
        }}
        onCancel={e => {
          modalManager.hide();
        }}
      />
    );
    modalManager.show('modalMaquinaForm');
  }

  function onClickRemoverMaquina(e, maquina) {
    modalManager.mount(
      'removeMaquinaDialog',
      <RemoveMaquinaDialog
        onConfirm={e => {
          let maquinaIdx = ordem.maquinas.indexOf(maquina);
          ordem.maquinas.splice(maquinaIdx, 1);
          setOrdem({ ...ordem });
          modalManager.hide();
        }}
        onCancel={e => {
          modalManager.hide();
        }}
      />
    );
    modalManager.show('removeMaquinaDialog');
  }

  function onClickEditarDisponibilidade(e, maquina, disponibilidade) {
    e.preventDefault();
    showModalDisponibilidadeForm(maquina, disponibilidade);
  }

  function showModalDisponibilidadeForm(maquina, disponibilidade) {
    modalManager.mount(
      'modalDisponibilidadeForm',
      <ModalDisponibilidadeForm
        disponibilidade={{ ...disponibilidade }}
        onConfirm={(e, disponibilidadeModificada) => {
          e.preventDefault();
          let disponibilidadeIndex = maquina.disponibilidades.indexOf(disponibilidade);
          maquina.disponibilidades[disponibilidadeIndex].inicio = disponibilidadeModificada.inicio;
          maquina.disponibilidades[disponibilidadeIndex].fim = disponibilidadeModificada.fim;
          setOrdem({ ...ordem });
          modalManager.hide();
        }}
        onCancel={e => {
          modalManager.hide();
        }}
      />
    );
    modalManager.show('modalDisponibilidadeForm');
  }

  function removeDisponibilidade(maquina, disponibilidade) {
    modalManager.mount(
      'removeMaquinaDialog',
      <RemoveDisponibilidadeDialog
        onConfirm={e => {
          let disponibilidadeIdx = maquina.disponibilidades.indexOf(disponibilidade);
          maquina.disponibilidades.splice(disponibilidadeIdx, 1);
          setOrdem({ ...ordem });
          modalManager.hide();
        }}
        onCancel={e => {
          modalManager.hide();
        }}
      />
    );
    modalManager.show('removeMaquinaDialog');
  }

  function onClickAdicionarEvento(e, maquina, tipo) {
    e.preventDefault();
    let evento = { ...EventoModel };
    let ultimaDisponibilidade = maquina.disponibilidades[maquina.disponibilidades.length - 1];
    let ultimoEvento = ultimaDisponibilidade && ultimaDisponibilidade.eventos.length > 0 ? ultimaDisponibilidade.eventos[ultimaDisponibilidade.eventos.length - 1] : null;
    let ultimoOperadorEscolhido = ultimoEvento ? ultimoEvento.operador : null;
    if (ultimoOperadorEscolhido && ultimoOperadorEscolhido.ativo && ultimoOperadorEscolhido.id > 0) {
      evento.operador = { ...ultimoOperadorEscolhido };
    }
    let ultimaDataPreenchida = ultimoEvento ? ultimoEvento.fim : '';
    evento.tipo = tipo;
    evento.inicio = ultimaDataPreenchida;
    evento.fim = ultimaDataPreenchida.slice(0, 10);
    showModalEventoForm(maquina, null, evento);
  }

  function onClickEditarEvento(e, maquina, disponibilidade, evento) {
    e.preventDefault();
    showModalEventoForm(maquina, disponibilidade, evento);
  }

  function showModalEventoForm(maquina, disponibilidade, evento) {
    modalManager.mount(
      'modalEventoForm',
      <ModalEventoForm
        operadores={context.funcionariosCache.filter(
          funcionario => funcionario.ativo || funcionario.id == evento.operador.id
        )}
        evento={{ ...evento }}
        onConfirm={(e, eventoModificado) => {
          e.preventDefault();

          // Remove o evento modificado para reinserir de forma correta depois
          if (disponibilidade) {
            let disponibilidadeIndex = maquina.disponibilidades.indexOf(disponibilidade);
            let eventoIndex = disponibilidade.eventos.indexOf(evento);
            if (eventoIndex > -1) {
              disponibilidade.eventos.splice(eventoIndex, 1);
            }
            if (disponibilidade.eventos.length === 0) {
              maquina.disponibilidades.splice(disponibilidadeIndex, 1);

              // Seta o ID do Evento para zero para o servidor deletar o evento anterior e adicionar um novo
              eventoModificado.id = 0;
            }
          }

          let disponibilidadeCompativelIndex = -1;
          
          // Procura uma disponibilidade compativel
          if (maquina.disponibilidades.length > 0) {
            let eventoInicio = (new Date(eventoModificado.inicio)).getTime();
            let quatroHorasEmMilisegundos = 14400000;
            disponibilidadeCompativelIndex = maquina.disponibilidades.findIndex(disp => {
              let disponibilidadeInicio = (new Date(disp.inicio)).getTime() - quatroHorasEmMilisegundos;
              let disponibilidadeFim = (new Date(disp.fim)).getTime() + quatroHorasEmMilisegundos;
              if (eventoInicio >= disponibilidadeInicio && eventoInicio <= disponibilidadeFim) {
                return true;
              }            
            });
          }

          // Se não tiver uma disponibilidade compatível cria uma
          if (disponibilidadeCompativelIndex < 0) {
            let disponibilidadeCompativel = JSON.parse(JSON.stringify(DisponibilidadeModel));
            disponibilidadeCompativel.inicio = eventoModificado.inicio;
            disponibilidadeCompativel.fim = eventoModificado.fim;
            maquina.disponibilidades = [...maquina.disponibilidades, disponibilidadeCompativel];
            disponibilidadeCompativelIndex = maquina.disponibilidades.indexOf(disponibilidadeCompativel);
          }

          // Adiciona o evento na disponibilidade compatível
          maquina.disponibilidades[disponibilidadeCompativelIndex].eventos = [...maquina.disponibilidades[disponibilidadeCompativelIndex].eventos, eventoModificado];

          // Ordena os eventos por data
          let eventosOrdenadosPorData = (maquina.disponibilidades[disponibilidadeCompativelIndex].eventos || []).sort((a, b) => {
            if (a.inicio > b.inicio) return 1;
            if (a.inicio < b.inicio) return -1;
            return 0;
          });          
          maquina.disponibilidades[disponibilidadeCompativelIndex].eventos = eventosOrdenadosPorData;

          // A data da disponibilidade passa a ser o inicio do primeiro evento e fim do último evento
          maquina.disponibilidades[disponibilidadeCompativelIndex].inicio = eventosOrdenadosPorData[0].inicio;
          maquina.disponibilidades[disponibilidadeCompativelIndex].fim = eventosOrdenadosPorData[eventosOrdenadosPorData.length - 1].fim;

          setOrdem({ ...ordem });
          modalManager.hide();
        }}
        onCancel={e => {
          modalManager.hide();
        }}
      />
    );
    modalManager.show('modalEventoForm');
  }

  function removeEvento(maquina, disponibilidade, evento) {
    modalManager.mount(
      'removeEventoDialog',
      <RemoveEventoDialog
        onConfirm={e => {
          let disponibilidadeIdx = maquina.disponibilidades.indexOf(disponibilidade);
          let eventoIdx = disponibilidade.eventos.indexOf(evento);
          disponibilidade.eventos.splice(eventoIdx, 1);
          if (disponibilidade.eventos.length === 0) {
            maquina.disponibilidades.splice(disponibilidadeIdx, 1);
          } else {
            disponibilidade.inicio = disponibilidade.eventos[0].inicio;
            disponibilidade.fim = disponibilidade.eventos[disponibilidade.eventos.length - 1].fim;
          }
          setOrdem({ ...ordem });
          modalManager.hide();
        }}
        onCancel={e => {
          modalManager.hide();
        }}
      />
    );
    modalManager.show('removeEventoDialog');
  }

  return (
    <>
      <div className="form-group-fluid eventosproducao-container">
        {(ordem.maquinas || []).map((maquina, index) => (
          <div key={index} className="form-group -box eventosdamaquina-container">
            <div className="form-group header">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label>Máquina</label>
                  <input
                    className="form-control"
                    value={maquina.nome}
                    disabled="disabled"
                  />
                </div>
                <div className="form-group col-md-12">
                  <label>Quantidade de Peças</label>
                  <input
                    className="form-control"
                    value={maquina.quantidadePecas || 0}
                    type="number"
                    onChange={e => {
                      maquina.quantidadePecas = e.target.value;
                      setOrdem({ ...ordem });
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="form-group body">
              {(maquina.disponibilidades || []).sort((a, b) => {
                if (a.inicio > b.inicio) return 1;
                if (a.inicio < b.inicio) return -1;
                return 0;
              }).map((disponibilidade, index) => {
                return (
                  <div key={index} className="disponibilidade-container">
                    <div className="header">
                      <div className="title">Disponibilidade de Máquina</div>
                      <div className="label">Início:</div>
                      <div className="value">{disponibilidade.inicio ? moment(disponibilidade.inicio).format('DD/MM/YYYY HH:mm:ss') : ''}</div>
                      <div className="label">Fim:</div>
                      <div className="value">{disponibilidade.fim ? moment(disponibilidade.fim).format('DD/MM/YYYY HH:mm:ss') : ''}</div>
                      <div className="menu-container">
                        <button type="button" onClick={e => onClickEditarDisponibilidade(e, maquina, disponibilidade)}>
                          <i className="icofont-ui-edit"></i> Modificar
                        </button>
                        <button type="button" onClick={e => removeDisponibilidade(maquina, disponibilidade)}>
                          <i className="icofont-ui-delete"></i> Remover
                        </button>
                      </div>
                    </div>
                    <div className="body">
                      {(disponibilidade.eventos || []).sort((a, b) => {
                        if (a.inicio > b.inicio) return 1;
                        if (a.inicio < b.inicio) return -1;
                        return 0;
                      }).map((evento, index) => {
                        let titulo = '';
                        let className = '';
                        let motivo = '';
                        switch (evento.tipo) {
                          case 'setup':
                            titulo = 'Setup';
                            className = 'setup';
                            break;
                          case 'producao':
                            titulo = 'Produção';
                            className = 'producao';
                            break;
                          case 'parada_programada':
                            titulo = 'Parada Programada';
                            className = 'parada';
                            break;
                          case 'parada_nao_programada':
                            titulo = 'Parada Não Programada';
                            className = 'parada';
                            break;
                        }

                        if (evento.tipo === 'parada_programada') {
                          switch (evento.motivo) {
                            case 'limpeza_de_maquina':
                              motivo = 'LIMPEZA DE MÁQUINA'; break;
                            case 'manutencao_preventiva':
                              motivo = 'MANUTENÇÃO PREVENTIVA'; break;
                            case 'raspar_chapa':
                              motivo = 'RASPAR CHAPAS'; break;
                            case 'refeicao':
                              motivo = 'REFEIÇÃO'; break;
                            case 'reuniao_treinamento':
                              motivo = 'REUNIÕES, TREINAMENTOS'; break;
                            case 'troca_de_cor':
                              motivo = 'TROCA DE CORES'; break;
                            case 'intervalos_de_operacao':
                              motivo = 'INTERVALOS DE OPERAÇÃO'; break;
                          }
                        } else if (evento.tipo === 'parada_nao_programada') {
                          switch (evento.motivo) {
                            case 'ajuste_de_cor':
                              motivo = 'AJUSTES DE CORES'; break;
                            case 'troca_de_quadro':
                              motivo = 'TROCA DE QUADROS'; break;
                            case 'conserto_de_quadro':
                              motivo = 'CONSERTO DE QUADROS'; break;
                            case 'falta_de_pessoal':
                              motivo = 'FALTA DE PESSOAL'; break;
                            case 'falta_de_ferramenta':
                              motivo = 'FALTA DE FERRAMENTAS'; break;
                            case 'falta_de_materia_prima':
                              motivo = 'FALTA DE MATÉRIA PRIMA'; break;
                            case 'manutencao_corretiva':
                              motivo = 'MANUTENÇÃO CORRETIVA'; break;
                            case 'quebra_de_linha':
                              motivo = 'QUEBRA DE LINHA'; break;
                            case 'limpeza_de_cisco':
                              motivo = 'LIMPEZA DE CISCO'; break;
                          }
                        }
                        
                        return (
                          <div key={index} className={`form-group evento ${className}`}>
                            <div className="titulo"><strong>{titulo}</strong></div>
                            <div className="form-row">
                              <div className="form-group col-12">
                                {motivo ? <>{motivo}<br /></> : ''}
                                <span className="label">Início:</span> <span className="value">{evento.inicio ? moment(evento.inicio).format('DD/MM/YYYY HH:mm:ss') : ''}</span>
                                <span className="label">Fim:</span> <span className="value">{evento.fim ? moment(evento.fim).format('DD/MM/YYYY HH:mm:ss') : ''}</span>
                                <span className="label">Op.:</span> <span className="value">{evento.operador.nome}</span>
                              </div>
                            </div>
                            {
                              permiteEditar() ?
                                <div className="menu-container">
                                  <button type="button" onClick={e => onClickEditarEvento(e, maquina, disponibilidade, evento)}>
                                    <i className="icofont-ui-edit"></i> Modificar
                                  </button>
                                  <button type="button" onClick={e => removeEvento(maquina, disponibilidade, evento)}>
                                    <i className="icofont-ui-delete"></i> Remover
                                  </button>
                                </div>
                                : ''
                            }
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )
              })}
            </div>

            {
              permiteEditar() ?
                <div className="footer">
                  <div className="title">Adicionar:</div>
                  <button onClick={e => onClickAdicionarEvento(e, maquina, 'setup')} type="button" className="addsetup">Setup</button>
                  <button onClick={e => onClickAdicionarEvento(e, maquina, 'producao')} type="button" className="addproducao">Produção</button>
                  <button onClick={e => onClickAdicionarEvento(e, maquina, 'parada_programada')} type="button" className="addparada">Parada Programada</button>
                  <button onClick={e => onClickAdicionarEvento(e, maquina, 'parada_nao_programada')} type="button" className="addparada">Parada Não Programada</button>

                  <button type="button" className="mt-4" onClick={e => onClickRemoverMaquina(e, maquina)}>
                    <i className="icofont-ui-delete"></i> Remover Máquina
                  </button>
                </div>
                : ''
            }
          </div>
        ))}
      </div>
      {
        permiteEditar() ?
          <button type="button" onClick={onClickAdicionarMaquina}>Adicionar Máquina</button>
          : ''
      }
    </>
  );
}